/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PtOtPrimaryDiagnosis = {
    JointReplacementOrSpinalSurgery: 'joint_replacement_or_spinal_surgery',
    OrthopedicSurgery: 'orthopedic_surgery',
    NonOrthopedicSurgery: 'non_orthopedic_surgery',
    AcuteInfections: 'acute_infections',
    CardiovascularCoagulations: 'cardiovascular_coagulations',
    Pulmonary: 'pulmonary',
    NonSurgicalOrthopedic: 'non_surgical_orthopedic',
    AcuteNeurologic: 'acute_neurologic',
    Cancer: 'cancer',
    MedicalManagement: 'medical_management'
} as const;
export type PtOtPrimaryDiagnosis = typeof PtOtPrimaryDiagnosis[keyof typeof PtOtPrimaryDiagnosis];


export function PtOtPrimaryDiagnosisFromJSON(json: any): PtOtPrimaryDiagnosis {
    return PtOtPrimaryDiagnosisFromJSONTyped(json, false);
}

export function PtOtPrimaryDiagnosisFromJSONTyped(json: any, ignoreDiscriminator: boolean): PtOtPrimaryDiagnosis {
    return json as PtOtPrimaryDiagnosis;
}

export function PtOtPrimaryDiagnosisToJSON(value?: PtOtPrimaryDiagnosis | null): any {
    return value as any;
}

