/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetMedicareEstimates200Response,
  GetMedicarePtOtMappings200Response,
  GetPtOtComponentForm200Response,
  MedicarePtOtForm,
} from '../models/index';
import {
    GetMedicareEstimates200ResponseFromJSON,
    GetMedicareEstimates200ResponseToJSON,
    GetMedicarePtOtMappings200ResponseFromJSON,
    GetMedicarePtOtMappings200ResponseToJSON,
    GetPtOtComponentForm200ResponseFromJSON,
    GetPtOtComponentForm200ResponseToJSON,
    MedicarePtOtFormFromJSON,
    MedicarePtOtFormToJSON,
} from '../models/index';

export interface GetMedicareEstimatesRequest {
    leadId: string;
}

export interface GetPtOtComponentFormRequest {
    leadId: string;
}

export interface UpdatePtOtComponentFormRequest {
    leadId: string;
    medicarePtOtForm: MedicarePtOtForm;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Get Medicare Reimbursment estimates for a lead
     */
    async getMedicareEstimatesRaw(requestParameters: GetMedicareEstimatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetMedicareEstimates200Response>> {
        if (requestParameters.leadId === null || requestParameters.leadId === undefined) {
            throw new runtime.RequiredError('leadId','Required parameter requestParameters.leadId was null or undefined when calling getMedicareEstimates.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/screener-leads/{lead_id}/medicare-estimates`.replace(`{${"lead_id"}}`, encodeURIComponent(String(requestParameters.leadId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetMedicareEstimates200ResponseFromJSON(jsonValue));
    }

    /**
     * Get Medicare Reimbursment estimates for a lead
     */
    async getMedicareEstimates(requestParameters: GetMedicareEstimatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetMedicareEstimates200Response> {
        const response = await this.getMedicareEstimatesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Medicare PT/OT diagnoses/case-mix group mappings
     */
    async getMedicarePtOtMappingsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetMedicarePtOtMappings200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/medicare/pt-ot-mappings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetMedicarePtOtMappings200ResponseFromJSON(jsonValue));
    }

    /**
     * Get Medicare PT/OT diagnoses/case-mix group mappings
     */
    async getMedicarePtOtMappings(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetMedicarePtOtMappings200Response> {
        const response = await this.getMedicarePtOtMappingsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get Medicare Reimbursment PT/OT component form for a lead
     */
    async getPtOtComponentFormRaw(requestParameters: GetPtOtComponentFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPtOtComponentForm200Response>> {
        if (requestParameters.leadId === null || requestParameters.leadId === undefined) {
            throw new runtime.RequiredError('leadId','Required parameter requestParameters.leadId was null or undefined when calling getPtOtComponentForm.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/screener-leads/{lead_id}/medicare-estimates/pt-ot`.replace(`{${"lead_id"}}`, encodeURIComponent(String(requestParameters.leadId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPtOtComponentForm200ResponseFromJSON(jsonValue));
    }

    /**
     * Get Medicare Reimbursment PT/OT component form for a lead
     */
    async getPtOtComponentForm(requestParameters: GetPtOtComponentFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPtOtComponentForm200Response> {
        const response = await this.getPtOtComponentFormRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Medicare PT/OT Function Scores and Primary Diagnosis for a lead
     */
    async updatePtOtComponentFormRaw(requestParameters: UpdatePtOtComponentFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPtOtComponentForm200Response>> {
        if (requestParameters.leadId === null || requestParameters.leadId === undefined) {
            throw new runtime.RequiredError('leadId','Required parameter requestParameters.leadId was null or undefined when calling updatePtOtComponentForm.');
        }

        if (requestParameters.medicarePtOtForm === null || requestParameters.medicarePtOtForm === undefined) {
            throw new runtime.RequiredError('medicarePtOtForm','Required parameter requestParameters.medicarePtOtForm was null or undefined when calling updatePtOtComponentForm.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/screener-leads/{lead_id}/medicare-estimates/pt-ot`.replace(`{${"lead_id"}}`, encodeURIComponent(String(requestParameters.leadId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MedicarePtOtFormToJSON(requestParameters.medicarePtOtForm),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPtOtComponentForm200ResponseFromJSON(jsonValue));
    }

    /**
     * Update Medicare PT/OT Function Scores and Primary Diagnosis for a lead
     */
    async updatePtOtComponentForm(requestParameters: UpdatePtOtComponentFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPtOtComponentForm200Response> {
        const response = await this.updatePtOtComponentFormRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
