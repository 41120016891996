/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MedicarePtOtForm } from './MedicarePtOtForm';
import {
    MedicarePtOtFormFromJSON,
    MedicarePtOtFormFromJSONTyped,
    MedicarePtOtFormToJSON,
} from './MedicarePtOtForm';

/**
 * 
 * @export
 * @interface GetPtOtComponentForm200Response
 */
export interface GetPtOtComponentForm200Response {
    /**
     * 
     * @type {MedicarePtOtForm}
     * @memberof GetPtOtComponentForm200Response
     */
    data?: MedicarePtOtForm;
}

/**
 * Check if a given object implements the GetPtOtComponentForm200Response interface.
 */
export function instanceOfGetPtOtComponentForm200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetPtOtComponentForm200ResponseFromJSON(json: any): GetPtOtComponentForm200Response {
    return GetPtOtComponentForm200ResponseFromJSONTyped(json, false);
}

export function GetPtOtComponentForm200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPtOtComponentForm200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : MedicarePtOtFormFromJSON(json['data']),
    };
}

export function GetPtOtComponentForm200ResponseToJSON(value?: GetPtOtComponentForm200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': MedicarePtOtFormToJSON(value.data),
    };
}

