/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PtOtCaseMixGroup } from './PtOtCaseMixGroup';
import {
    PtOtCaseMixGroupFromJSON,
    PtOtCaseMixGroupFromJSONTyped,
    PtOtCaseMixGroupToJSON,
} from './PtOtCaseMixGroup';
import type { PtOtFunctionScoreRange } from './PtOtFunctionScoreRange';
import {
    PtOtFunctionScoreRangeFromJSON,
    PtOtFunctionScoreRangeFromJSONTyped,
    PtOtFunctionScoreRangeToJSON,
} from './PtOtFunctionScoreRange';

/**
 * 
 * @export
 * @interface PtOtCaseMixGroupScoreRange
 */
export interface PtOtCaseMixGroupScoreRange {
    /**
     * 
     * @type {PtOtFunctionScoreRange}
     * @memberof PtOtCaseMixGroupScoreRange
     */
    function_score_range?: PtOtFunctionScoreRange;
    /**
     * 
     * @type {PtOtCaseMixGroup}
     * @memberof PtOtCaseMixGroupScoreRange
     */
    case_mix_group?: PtOtCaseMixGroup;
    /**
     * 
     * @type {number}
     * @memberof PtOtCaseMixGroupScoreRange
     */
    pt_cmi?: number;
    /**
     * 
     * @type {number}
     * @memberof PtOtCaseMixGroupScoreRange
     */
    ot_cmi?: number;
}

/**
 * Check if a given object implements the PtOtCaseMixGroupScoreRange interface.
 */
export function instanceOfPtOtCaseMixGroupScoreRange(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PtOtCaseMixGroupScoreRangeFromJSON(json: any): PtOtCaseMixGroupScoreRange {
    return PtOtCaseMixGroupScoreRangeFromJSONTyped(json, false);
}

export function PtOtCaseMixGroupScoreRangeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PtOtCaseMixGroupScoreRange {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'function_score_range': !exists(json, 'function_score_range') ? undefined : PtOtFunctionScoreRangeFromJSON(json['function_score_range']),
        'case_mix_group': !exists(json, 'case_mix_group') ? undefined : PtOtCaseMixGroupFromJSON(json['case_mix_group']),
        'pt_cmi': !exists(json, 'pt_cmi') ? undefined : json['pt_cmi'],
        'ot_cmi': !exists(json, 'ot_cmi') ? undefined : json['ot_cmi'],
    };
}

export function PtOtCaseMixGroupScoreRangeToJSON(value?: PtOtCaseMixGroupScoreRange | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'function_score_range': PtOtFunctionScoreRangeToJSON(value.function_score_range),
        'case_mix_group': PtOtCaseMixGroupToJSON(value.case_mix_group),
        'pt_cmi': value.pt_cmi,
        'ot_cmi': value.ot_cmi,
    };
}

