/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PtOtFunctionScore } from './PtOtFunctionScore';
import {
    PtOtFunctionScoreFromJSON,
    PtOtFunctionScoreFromJSONTyped,
    PtOtFunctionScoreToJSON,
} from './PtOtFunctionScore';

/**
 * 
 * @export
 * @interface PtOtScores
 */
export interface PtOtScores {
    /**
     * 
     * @type {Array<PtOtFunctionScore>}
     * @memberof PtOtScores
     */
    eating: Array<PtOtFunctionScore>;
    /**
     * 
     * @type {any}
     * @memberof PtOtScores
     */
    oral_hygiene: any | null;
    /**
     * 
     * @type {any}
     * @memberof PtOtScores
     */
    toileting: any | null;
    /**
     * 
     * @type {any}
     * @memberof PtOtScores
     */
    bed_mobility: any | null;
    /**
     * 
     * @type {any}
     * @memberof PtOtScores
     */
    transfer: any | null;
    /**
     * 
     * @type {any}
     * @memberof PtOtScores
     */
    walking: any | null;
}

/**
 * Check if a given object implements the PtOtScores interface.
 */
export function instanceOfPtOtScores(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "eating" in value;
    isInstance = isInstance && "oral_hygiene" in value;
    isInstance = isInstance && "toileting" in value;
    isInstance = isInstance && "bed_mobility" in value;
    isInstance = isInstance && "transfer" in value;
    isInstance = isInstance && "walking" in value;

    return isInstance;
}

export function PtOtScoresFromJSON(json: any): PtOtScores {
    return PtOtScoresFromJSONTyped(json, false);
}

export function PtOtScoresFromJSONTyped(json: any, ignoreDiscriminator: boolean): PtOtScores {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'eating': ((json['eating'] as Array<any>).map(PtOtFunctionScoreFromJSON)),
        'oral_hygiene': json['oral_hygiene'],
        'toileting': json['toileting'],
        'bed_mobility': json['bed_mobility'],
        'transfer': json['transfer'],
        'walking': json['walking'],
    };
}

export function PtOtScoresToJSON(value?: PtOtScores | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'eating': ((value.eating as Array<any>).map(PtOtFunctionScoreToJSON)),
        'oral_hygiene': value.oral_hygiene,
        'toileting': value.toileting,
        'bed_mobility': value.bed_mobility,
        'transfer': value.transfer,
        'walking': value.walking,
    };
}

