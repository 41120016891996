/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { VisualReference } from './VisualReference';
import {
    VisualReferenceFromJSON,
    VisualReferenceFromJSONTyped,
    VisualReferenceToJSON,
} from './VisualReference';

/**
 * 
 * @export
 * @interface PtOtFunctionScore
 */
export interface PtOtFunctionScore {
    /**
     * 
     * @type {string}
     * @memberof PtOtFunctionScore
     */
    readonly action?: string;
    /**
     * 
     * @type {number}
     * @memberof PtOtFunctionScore
     */
    readonly predicted_score?: number;
    /**
     * 
     * @type {number}
     * @memberof PtOtFunctionScore
     */
    overridden_score?: number | null;
    /**
     * 
     * @type {Array<VisualReference>}
     * @memberof PtOtFunctionScore
     */
    visual_references?: Array<VisualReference>;
}

/**
 * Check if a given object implements the PtOtFunctionScore interface.
 */
export function instanceOfPtOtFunctionScore(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PtOtFunctionScoreFromJSON(json: any): PtOtFunctionScore {
    return PtOtFunctionScoreFromJSONTyped(json, false);
}

export function PtOtFunctionScoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): PtOtFunctionScore {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'action': !exists(json, 'action') ? undefined : json['action'],
        'predicted_score': !exists(json, 'predicted_score') ? undefined : json['predicted_score'],
        'overridden_score': !exists(json, 'overridden_score') ? undefined : json['overridden_score'],
        'visual_references': !exists(json, 'visual_references') ? undefined : ((json['visual_references'] as Array<any>).map(VisualReferenceFromJSON)),
    };
}

export function PtOtFunctionScoreToJSON(value?: PtOtFunctionScore | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'overridden_score': value.overridden_score,
        'visual_references': value.visual_references === undefined ? undefined : ((value.visual_references as Array<any>).map(VisualReferenceToJSON)),
    };
}

