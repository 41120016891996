/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PtOtCaseMixGroup } from './PtOtCaseMixGroup';
import {
    PtOtCaseMixGroupFromJSON,
    PtOtCaseMixGroupFromJSONTyped,
    PtOtCaseMixGroupToJSON,
} from './PtOtCaseMixGroup';
import type { PtOtClinicalCategory } from './PtOtClinicalCategory';
import {
    PtOtClinicalCategoryFromJSON,
    PtOtClinicalCategoryFromJSONTyped,
    PtOtClinicalCategoryToJSON,
} from './PtOtClinicalCategory';
import type { PtOtPrimaryDiagnosis } from './PtOtPrimaryDiagnosis';
import {
    PtOtPrimaryDiagnosisFromJSON,
    PtOtPrimaryDiagnosisFromJSONTyped,
    PtOtPrimaryDiagnosisToJSON,
} from './PtOtPrimaryDiagnosis';
import type { PtOtScores } from './PtOtScores';
import {
    PtOtScoresFromJSON,
    PtOtScoresFromJSONTyped,
    PtOtScoresToJSON,
} from './PtOtScores';

/**
 * 
 * @export
 * @interface MedicarePtOtForm
 */
export interface MedicarePtOtForm {
    /**
     * 
     * @type {PtOtPrimaryDiagnosis}
     * @memberof MedicarePtOtForm
     */
    readonly predicted_primary_diagnosis: PtOtPrimaryDiagnosis;
    /**
     * 
     * @type {PtOtPrimaryDiagnosis}
     * @memberof MedicarePtOtForm
     */
    overridden_primary_diagnosis?: PtOtPrimaryDiagnosis;
    /**
     * 
     * @type {PtOtClinicalCategory}
     * @memberof MedicarePtOtForm
     */
    readonly clinical_category: PtOtClinicalCategory;
    /**
     * 
     * @type {PtOtScores}
     * @memberof MedicarePtOtForm
     */
    scores: PtOtScores;
    /**
     * 
     * @type {number}
     * @memberof MedicarePtOtForm
     */
    readonly score_total: number;
    /**
     * 
     * @type {PtOtCaseMixGroup}
     * @memberof MedicarePtOtForm
     */
    readonly case_mix_group: PtOtCaseMixGroup;
}

/**
 * Check if a given object implements the MedicarePtOtForm interface.
 */
export function instanceOfMedicarePtOtForm(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "predicted_primary_diagnosis" in value;
    isInstance = isInstance && "clinical_category" in value;
    isInstance = isInstance && "scores" in value;
    isInstance = isInstance && "score_total" in value;
    isInstance = isInstance && "case_mix_group" in value;

    return isInstance;
}

export function MedicarePtOtFormFromJSON(json: any): MedicarePtOtForm {
    return MedicarePtOtFormFromJSONTyped(json, false);
}

export function MedicarePtOtFormFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicarePtOtForm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'predicted_primary_diagnosis': PtOtPrimaryDiagnosisFromJSON(json['predicted_primary_diagnosis']),
        'overridden_primary_diagnosis': !exists(json, 'overridden_primary_diagnosis') ? undefined : PtOtPrimaryDiagnosisFromJSON(json['overridden_primary_diagnosis']),
        'clinical_category': PtOtClinicalCategoryFromJSON(json['clinical_category']),
        'scores': PtOtScoresFromJSON(json['scores']),
        'score_total': json['score_total'],
        'case_mix_group': PtOtCaseMixGroupFromJSON(json['case_mix_group']),
    };
}

export function MedicarePtOtFormToJSON(value?: MedicarePtOtForm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'overridden_primary_diagnosis': PtOtPrimaryDiagnosisToJSON(value.overridden_primary_diagnosis),
        'scores': PtOtScoresToJSON(value.scores),
    };
}

