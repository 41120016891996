/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FacilityIntegration
 */
export interface FacilityIntegration {
    /**
     * 
     * @type {string}
     * @memberof FacilityIntegration
     */
    name: FacilityIntegrationNameEnum;
    /**
     * 
     * @type {string}
     * @memberof FacilityIntegration
     */
    status?: FacilityIntegrationStatusEnum;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof FacilityIntegration
     */
    attributes?: { [key: string]: any; };
}


/**
 * @export
 */
export const FacilityIntegrationNameEnum = {
    Pcc: 'pcc',
    Fax: 'fax',
    WellskyIntake: 'wellsky_intake',
    WellskyReferralManagement: 'wellsky_referral_management',
    Ensocare: 'ensocare',
    Aidin: 'aidin',
    Aida: 'aida'
} as const;
export type FacilityIntegrationNameEnum = typeof FacilityIntegrationNameEnum[keyof typeof FacilityIntegrationNameEnum];

/**
 * @export
 */
export const FacilityIntegrationStatusEnum = {
    Complete: 'complete',
    Pending: 'pending',
    Disabled: 'disabled'
} as const;
export type FacilityIntegrationStatusEnum = typeof FacilityIntegrationStatusEnum[keyof typeof FacilityIntegrationStatusEnum];


/**
 * Check if a given object implements the FacilityIntegration interface.
 */
export function instanceOfFacilityIntegration(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function FacilityIntegrationFromJSON(json: any): FacilityIntegration {
    return FacilityIntegrationFromJSONTyped(json, false);
}

export function FacilityIntegrationFromJSONTyped(json: any, ignoreDiscriminator: boolean): FacilityIntegration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'attributes': !exists(json, 'attributes') ? undefined : json['attributes'],
    };
}

export function FacilityIntegrationToJSON(value?: FacilityIntegration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'status': value.status,
        'attributes': value.attributes,
    };
}

