/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MedicareComponentEstimate } from './MedicareComponentEstimate';
import {
    MedicareComponentEstimateFromJSON,
    MedicareComponentEstimateFromJSONTyped,
    MedicareComponentEstimateToJSON,
} from './MedicareComponentEstimate';

/**
 * 
 * @export
 * @interface MedicareEstimates
 */
export interface MedicareEstimates {
    /**
     * 
     * @type {Array<MedicareComponentEstimate>}
     * @memberof MedicareEstimates
     */
    estimates: Array<MedicareComponentEstimate>;
}

/**
 * Check if a given object implements the MedicareEstimates interface.
 */
export function instanceOfMedicareEstimates(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "estimates" in value;

    return isInstance;
}

export function MedicareEstimatesFromJSON(json: any): MedicareEstimates {
    return MedicareEstimatesFromJSONTyped(json, false);
}

export function MedicareEstimatesFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicareEstimates {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'estimates': ((json['estimates'] as Array<any>).map(MedicareComponentEstimateFromJSON)),
    };
}

export function MedicareEstimatesToJSON(value?: MedicareEstimates | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'estimates': ((value.estimates as Array<any>).map(MedicareComponentEstimateToJSON)),
    };
}

