/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DocumentProcessingStatus } from './DocumentProcessingStatus';
import {
    DocumentProcessingStatusFromJSON,
    DocumentProcessingStatusFromJSONTyped,
    DocumentProcessingStatusToJSON,
} from './DocumentProcessingStatus';
import type { PdfConversionStatus } from './PdfConversionStatus';
import {
    PdfConversionStatusFromJSON,
    PdfConversionStatusFromJSONTyped,
    PdfConversionStatusToJSON,
} from './PdfConversionStatus';

/**
 * 
 * @export
 * @interface Document
 */
export interface Document {
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    lead_id?: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    s3_key: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    s3_access_url?: string;
    /**
     * 
     * @type {DocumentProcessingStatus}
     * @memberof Document
     */
    processing_status?: DocumentProcessingStatus;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    processing_error?: string;
    /**
     * 
     * @type {PdfConversionStatus}
     * @memberof Document
     */
    pdf_conversion_status?: PdfConversionStatus;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    orig_s3_key?: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    content_type?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Document
     */
    has_been_analyzed?: boolean;
}

/**
 * Check if a given object implements the Document interface.
 */
export function instanceOfDocument(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "s3_key" in value;

    return isInstance;
}

export function DocumentFromJSON(json: any): Document {
    return DocumentFromJSONTyped(json, false);
}

export function DocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Document {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'lead_id': !exists(json, 'lead_id') ? undefined : json['lead_id'],
        'name': json['name'],
        's3_key': json['s3_key'],
        's3_access_url': !exists(json, 's3_access_url') ? undefined : json['s3_access_url'],
        'processing_status': !exists(json, 'processing_status') ? undefined : DocumentProcessingStatusFromJSON(json['processing_status']),
        'processing_error': !exists(json, 'processing_error') ? undefined : json['processing_error'],
        'pdf_conversion_status': !exists(json, 'pdf_conversion_status') ? undefined : PdfConversionStatusFromJSON(json['pdf_conversion_status']),
        'orig_s3_key': !exists(json, 'orig_s3_key') ? undefined : json['orig_s3_key'],
        'content_type': !exists(json, 'content_type') ? undefined : json['content_type'],
        'has_been_analyzed': !exists(json, 'has_been_analyzed') ? undefined : json['has_been_analyzed'],
    };
}

export function DocumentToJSON(value?: Document | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'lead_id': value.lead_id,
        'name': value.name,
        's3_key': value.s3_key,
        's3_access_url': value.s3_access_url,
        'processing_status': DocumentProcessingStatusToJSON(value.processing_status),
        'processing_error': value.processing_error,
        'pdf_conversion_status': PdfConversionStatusToJSON(value.pdf_conversion_status),
        'orig_s3_key': value.orig_s3_key,
        'content_type': value.content_type,
        'has_been_analyzed': value.has_been_analyzed,
    };
}

