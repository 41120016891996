/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-insurances
 * crm-screener-insurances
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MedicareComponentEstimate
 */
export interface MedicareComponentEstimate {
    /**
     * 
     * @type {string}
     * @memberof MedicareComponentEstimate
     */
    component: string;
    /**
     * 
     * @type {string}
     * @memberof MedicareComponentEstimate
     */
    case_mix_group: string;
    /**
     * 
     * @type {number}
     * @memberof MedicareComponentEstimate
     */
    daily_rate: number;
}

/**
 * Check if a given object implements the MedicareComponentEstimate interface.
 */
export function instanceOfMedicareComponentEstimate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "component" in value;
    isInstance = isInstance && "case_mix_group" in value;
    isInstance = isInstance && "daily_rate" in value;

    return isInstance;
}

export function MedicareComponentEstimateFromJSON(json: any): MedicareComponentEstimate {
    return MedicareComponentEstimateFromJSONTyped(json, false);
}

export function MedicareComponentEstimateFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicareComponentEstimate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'component': json['component'],
        'case_mix_group': json['case_mix_group'],
        'daily_rate': json['daily_rate'],
    };
}

export function MedicareComponentEstimateToJSON(value?: MedicareComponentEstimate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'component': value.component,
        'case_mix_group': value.case_mix_group,
        'daily_rate': value.daily_rate,
    };
}

