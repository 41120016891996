import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Fade,
  ListItem,
  ListItemIcon,
  ListItemProps,
  ListItemText
} from '@mui/material';

import { useCurrentUser } from '@/hooks/useCurrentUser';
import {
  useDynamicPermissionQuery,
  UserDynamicPermissions
} from '@/pages/AdminHub/Staff/hooks/useDynamicPermissionQuery';

import { itemEHR } from './getSidebarCRMItems';
import { itemCRM } from './getSidebarEHRItems';
import { isCurrentPath, StyledNavButtonOutlined } from './Sidebar';

type CrmEhrModuleButtonProps = {
  expanded: boolean;
  handleOnClick?: VoidFunction;
} & ListItemProps;

export const CrmEhrModuleButton: React.FC<CrmEhrModuleButtonProps> = ({
  expanded,
  handleOnClick,
  ...props
}) => {
  const currentUser = useCurrentUser().data!;
  const pathname = useLocation().pathname;
  const moduleItem = pathname.startsWith('/crm') ? itemEHR : itemCRM;

  const { data: dynamicPermissions } =
    useDynamicPermissionQuery().findAllUserDynamicPermissions(
      { id: currentUser.id },
      {
        enabled: !!currentUser?.id
      }
    );

  const isCRMEnabledForCurrentUser = dynamicPermissions?.find(
    (permission) => permission.name === UserDynamicPermissions.CRM_GENERAL
  )?.enabled;

  if (!isCRMEnabledForCurrentUser || !moduleItem) {
    return null;
  }

  return (
    <ListItem {...props}>
      <StyledNavButtonOutlined
        // @ts-ignore
        component={Link}
        to={moduleItem.path}
        onClick={handleOnClick}
        {...(moduleItem.state && { state: moduleItem.state })}
        selected={isCurrentPath(moduleItem, pathname)}>
        <ListItemIcon>{moduleItem.icon}</ListItemIcon>
        <Fade in={expanded}>
          <ListItemText primary={moduleItem.text} />
        </Fade>
      </StyledNavButtonOutlined>
    </ListItem>
  );
};
