import React from 'react';
import {
  CorporateFare,
  LibraryAddCheck,
  Medication,
  Person,
  ReduceCapacity,
  Task
} from '@mui/icons-material';
import { useAtomValue } from 'jotai';

import {
  TasksLocationState,
  tasksPageViewAtom
} from '@/components/Tasks/TasksToggleView';
import { useCurrentUser } from '@/hooks/useCurrentUser';
import { RoutePath } from '@/routes/RoutePath';
import { ExacareFeature, FeatureFlagService } from '@/utils/featureFlagService';
import { generatePathWithDomain } from '@/utils/generatePathWithDomain';

import { SidebarItemBadgeProps } from './SidebarItemBadge';

export interface SidebarItem {
  icon: JSX.Element;
  text: string;
  path: string;
  state?: TasksLocationState;
  badgeProps?: Partial<SidebarItemBadgeProps>;
  showOnMobile?: boolean;
}

export const getSidebarEHRItems = (): SidebarItem[] => {
  const currentUser = useCurrentUser().data!;
  return [
    {
      icon: <Person />,
      text: 'Resident Overview',
      path: RoutePath.ResidentOverview,
      showOnMobile: true
    },
    ...(currentUser.hasMinimumUserRole('L4')
      ? [
          {
            icon: <LibraryAddCheck />,
            text: 'Tasks',
            path: useAtomValue(tasksPageViewAtom),
            state: { refetchOnMount: 'always' } as TasksLocationState,
            showOnMobile: true
          }
        ]
      : []),
    ...(currentUser.hasMinimumUserRole('L1')
      ? [
          {
            icon: <CorporateFare />,
            text: 'Admin Hub',
            path: generatePathWithDomain(RoutePath.AdminHub)
          }
        ]
      : []),
    ...(FeatureFlagService.isEnabled(ExacareFeature.PHARMACY) &&
    currentUser.hasMinimumUserRole('L3')
      ? [
          {
            icon: <Medication />,
            text: 'Pharmacy',
            path: RoutePath.PharmacyMedicationView
          }
        ]
      : []),
    ...(!FeatureFlagService.isEnabled(ExacareFeature.PHARMACY)
      ? [
          {
            icon: <Medication />,
            text: 'Medication Overview',
            path: RoutePath.MedicationStock
          }
        ]
      : []),
    ...(FeatureFlagService.isEnabled(ExacareFeature.DATA_CENTER_TAB) &&
    currentUser.isDataCenterEnabled()
      ? [
          {
            icon: <Task />,
            text: 'Data Center',
            path: generatePathWithDomain(RoutePath.DataCenterDashboards)
          }
        ]
      : [])
  ];
};

export const itemCRM: SidebarItem = {
  icon: <ReduceCapacity />,
  text: 'CRM',
  path: RoutePath.CRMProspectsResidents
};
