/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PccPatientAllergy } from './PccPatientAllergy';
import {
    PccPatientAllergyFromJSON,
    PccPatientAllergyFromJSONTyped,
    PccPatientAllergyToJSON,
} from './PccPatientAllergy';
import type { PccPatientCarePeriod } from './PccPatientCarePeriod';
import {
    PccPatientCarePeriodFromJSON,
    PccPatientCarePeriodFromJSONTyped,
    PccPatientCarePeriodToJSON,
} from './PccPatientCarePeriod';
import type { PccPatientCondition } from './PccPatientCondition';
import {
    PccPatientConditionFromJSON,
    PccPatientConditionFromJSONTyped,
    PccPatientConditionToJSON,
} from './PccPatientCondition';
import type { PccPatientContact } from './PccPatientContact';
import {
    PccPatientContactFromJSON,
    PccPatientContactFromJSONTyped,
    PccPatientContactToJSON,
} from './PccPatientContact';
import type { PccPatientOrganizationDetails } from './PccPatientOrganizationDetails';
import {
    PccPatientOrganizationDetailsFromJSON,
    PccPatientOrganizationDetailsFromJSONTyped,
    PccPatientOrganizationDetailsToJSON,
} from './PccPatientOrganizationDetails';
import type { UploadPccPatientDetailsRequest } from './UploadPccPatientDetailsRequest';
import {
    UploadPccPatientDetailsRequestFromJSON,
    UploadPccPatientDetailsRequestFromJSONTyped,
    UploadPccPatientDetailsRequestToJSON,
} from './UploadPccPatientDetailsRequest';

/**
 * 
 * @export
 * @interface PccAdvancedPatientDetails
 */
export interface PccAdvancedPatientDetails {
    /**
     * 
     * @type {PccPatientCarePeriod}
     * @memberof PccAdvancedPatientDetails
     */
    care_period?: PccPatientCarePeriod;
    /**
     * 
     * @type {PccPatientOrganizationDetails}
     * @memberof PccAdvancedPatientDetails
     */
    organization_details?: PccPatientOrganizationDetails;
    /**
     * 
     * @type {Array<PccPatientContact>}
     * @memberof PccAdvancedPatientDetails
     */
    contacts?: Array<PccPatientContact>;
    /**
     * 
     * @type {Array<PccPatientAllergy>}
     * @memberof PccAdvancedPatientDetails
     */
    allergies?: Array<PccPatientAllergy>;
    /**
     * 
     * @type {Array<PccPatientCondition>}
     * @memberof PccAdvancedPatientDetails
     */
    conditions?: Array<PccPatientCondition>;
    /**
     * 
     * @type {UploadPccPatientDetailsRequest}
     * @memberof PccAdvancedPatientDetails
     */
    documents?: UploadPccPatientDetailsRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PccAdvancedPatientDetails
     */
    upload_to_pcc?: boolean;
}

/**
 * Check if a given object implements the PccAdvancedPatientDetails interface.
 */
export function instanceOfPccAdvancedPatientDetails(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PccAdvancedPatientDetailsFromJSON(json: any): PccAdvancedPatientDetails {
    return PccAdvancedPatientDetailsFromJSONTyped(json, false);
}

export function PccAdvancedPatientDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PccAdvancedPatientDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'care_period': !exists(json, 'care_period') ? undefined : PccPatientCarePeriodFromJSON(json['care_period']),
        'organization_details': !exists(json, 'organization_details') ? undefined : PccPatientOrganizationDetailsFromJSON(json['organization_details']),
        'contacts': !exists(json, 'contacts') ? undefined : ((json['contacts'] as Array<any>).map(PccPatientContactFromJSON)),
        'allergies': !exists(json, 'allergies') ? undefined : ((json['allergies'] as Array<any>).map(PccPatientAllergyFromJSON)),
        'conditions': !exists(json, 'conditions') ? undefined : ((json['conditions'] as Array<any>).map(PccPatientConditionFromJSON)),
        'documents': !exists(json, 'documents') ? undefined : UploadPccPatientDetailsRequestFromJSON(json['documents']),
        'upload_to_pcc': !exists(json, 'upload_to_pcc') ? undefined : json['upload_to_pcc'],
    };
}

export function PccAdvancedPatientDetailsToJSON(value?: PccAdvancedPatientDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'care_period': PccPatientCarePeriodToJSON(value.care_period),
        'organization_details': PccPatientOrganizationDetailsToJSON(value.organization_details),
        'contacts': value.contacts === undefined ? undefined : ((value.contacts as Array<any>).map(PccPatientContactToJSON)),
        'allergies': value.allergies === undefined ? undefined : ((value.allergies as Array<any>).map(PccPatientAllergyToJSON)),
        'conditions': value.conditions === undefined ? undefined : ((value.conditions as Array<any>).map(PccPatientConditionToJSON)),
        'documents': UploadPccPatientDetailsRequestToJSON(value.documents),
        'upload_to_pcc': value.upload_to_pcc,
    };
}

