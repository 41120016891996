/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LeadCommentReaction } from './LeadCommentReaction';
import {
    LeadCommentReactionFromJSON,
    LeadCommentReactionFromJSONTyped,
    LeadCommentReactionToJSON,
} from './LeadCommentReaction';
import type { LeadCommentReferenceType } from './LeadCommentReferenceType';
import {
    LeadCommentReferenceTypeFromJSON,
    LeadCommentReferenceTypeFromJSONTyped,
    LeadCommentReferenceTypeToJSON,
} from './LeadCommentReferenceType';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface LeadComment
 */
export interface LeadComment {
    /**
     * 
     * @type {string}
     * @memberof LeadComment
     */
    id?: string;
    /**
     * 
     * @type {object}
     * @memberof LeadComment
     */
    comment?: object;
    /**
     * 
     * @type {string}
     * @memberof LeadComment
     */
    lead_id: string;
    /**
     * 
     * @type {string}
     * @memberof LeadComment
     */
    created_by_user_id?: string;
    /**
     * 
     * @type {User}
     * @memberof LeadComment
     */
    created_by_user?: User;
    /**
     * 
     * @type {string}
     * @memberof LeadComment
     */
    reference_id?: string;
    /**
     * 
     * @type {LeadCommentReferenceType}
     * @memberof LeadComment
     */
    reference_type?: LeadCommentReferenceType;
    /**
     * Can be any value since a lead comment reference can be any valid record. e.g. LeadMedication or AnalysisResult
     * @type {object}
     * @memberof LeadComment
     */
    reference?: object;
    /**
     * 
     * @type {string}
     * @memberof LeadComment
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadComment
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadComment
     */
    deletedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadComment
     */
    parent_comment_id?: string;
    /**
     * 
     * @type {Array<LeadComment>}
     * @memberof LeadComment
     */
    comment_replies?: Array<LeadComment>;
    /**
     * 
     * @type {Array<LeadCommentReaction>}
     * @memberof LeadComment
     */
    reactions?: Array<LeadCommentReaction>;
}

/**
 * Check if a given object implements the LeadComment interface.
 */
export function instanceOfLeadComment(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "lead_id" in value;

    return isInstance;
}

export function LeadCommentFromJSON(json: any): LeadComment {
    return LeadCommentFromJSONTyped(json, false);
}

export function LeadCommentFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeadComment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'lead_id': json['lead_id'],
        'created_by_user_id': !exists(json, 'created_by_user_id') ? undefined : json['created_by_user_id'],
        'created_by_user': !exists(json, 'created_by_user') ? undefined : UserFromJSON(json['created_by_user']),
        'reference_id': !exists(json, 'reference_id') ? undefined : json['reference_id'],
        'reference_type': !exists(json, 'reference_type') ? undefined : LeadCommentReferenceTypeFromJSON(json['reference_type']),
        'reference': !exists(json, 'reference') ? undefined : json['reference'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'deletedAt': !exists(json, 'deletedAt') ? undefined : json['deletedAt'],
        'parent_comment_id': !exists(json, 'parent_comment_id') ? undefined : json['parent_comment_id'],
        'comment_replies': !exists(json, 'comment_replies') ? undefined : ((json['comment_replies'] as Array<any>).map(LeadCommentFromJSON)),
        'reactions': !exists(json, 'reactions') ? undefined : ((json['reactions'] as Array<any>).map(LeadCommentReactionFromJSON)),
    };
}

export function LeadCommentToJSON(value?: LeadComment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'comment': value.comment,
        'lead_id': value.lead_id,
        'created_by_user_id': value.created_by_user_id,
        'created_by_user': UserToJSON(value.created_by_user),
        'reference_id': value.reference_id,
        'reference_type': LeadCommentReferenceTypeToJSON(value.reference_type),
        'reference': value.reference,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'deletedAt': value.deletedAt,
        'parent_comment_id': value.parent_comment_id,
        'comment_replies': value.comment_replies === undefined ? undefined : ((value.comment_replies as Array<any>).map(LeadCommentToJSON)),
        'reactions': value.reactions === undefined ? undefined : ((value.reactions as Array<any>).map(LeadCommentReactionToJSON)),
    };
}

